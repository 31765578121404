import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../../components/news-strip"
import imgDigitalWalkaround from "../../images/news/digital-walkaround-checks.jpg"
import imgDigitalMaintenance from "../../images/news/digital-periodic-maintenance.jpg"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Digital transformation at Tetley's Coaches" description="Like many businesses over the past 12 months, we have been extremely quiet with the restrictions not allowing for our usual private hire and coach travel bookings. We have used this time to reflect and look at the systems we use to help run our business and have now implemented digital driver walk-around checks and digital periodic maintenance inspections." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Digital transformation at Tetley's Coaches</h1>
              <p className="news-article-date">April 2021</p>
            </div>

            <div className="news-article-body">
              <p>Like many businesses over the past 12 months, we have been extremely quiet with the restrictions not allowing for our usual private hire and coach travel bookings. We have used this time to reflect and look at the systems we use to help run our business. Searching for improvements that we could integrate into our operation allowing our teams to work smarter together.</p>
              <p>After successful trials we have now implemented the following resources:</p>
              <h3 style={{ marginBottom: 10 }}>Driver digital walk-around checks</h3>
              <img src={imgDigitalWalkaround} alt="" />
              <p>Drivers every morning perform a safety check on all vehicles before departing from the yard. Any defects are logged via the app which then alerts the traffic office and our in-house maintenance staff. </p>
              <p>This technology puts in place a critical link between drivers, managers and maintenance and allows us to react instantly on any issues with vehicles that may be found prior to commencing service.</p>
              <h3 style={{ marginBottom: 10 }}>Digital periodic maintenance inspections</h3>
              <img src={imgDigitalMaintenance} alt="" />
              <p>All our vehicles undergo 6 weekly periodic maintenance inspections by our fully qualified in-house maintenance staff. We inspect all the critical components at these intervals to ensure the fleet is always in a roadworthy condition.</p>
              <p>Inspections are recorded via tablet and any remedial work required is loaded up and integrated within our scheduling system. This alerts schedulers to any maintenance work required and will de-schedule vehicles from service if any problems are found that could affect the roadworthiness of a vehicle.</p>
              <h3 style={{ marginBottom: 10 }}>OCRS score</h3>
              <p>Passenger safety has always been our absolute priority. The systems outlined above will strengthen this commitment and allow an integrated system of working together between our departments and staff.</p>
              <p>Tetley’s Motor services proudly have the rank of Green – Low risk operator with the DVSA’s Operators Compliance Risk Score (OCRS) system. You can find more information <a href="https://www.gov.uk/operator-compliance-risk-score/how-the-system-works">HERE</a> on how the OCRS system works.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
